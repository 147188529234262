<template>
  <div class="map-container" :style="styleObject">
    <div class="amap-wrapper" :style="styleObject">
      <el-amap
        class="amap-box"
        :vid="'amap-vue'"
        zoom="18"
        :zooms="[3, 20]"
        :center="center"
        :expandZoomRange="true"
        :plugin="plugins"
        v-if="center.length > 0 && loaded"
      >
        <el-amap-marker
          v-for="(item, index) in markers"
          :position="item.position"
          :content="item.icon"
          :key="index"
          :events="item.events"
        ></el-amap-marker>
        <el-amap-info-window
          v-for="(window, index) in windows"
          :position="window.position"
          :visible="window.visible"
          :content="window.content"
          :offset="window.offset"
          :key="index + 'w'"
        ></el-amap-info-window>
        <el-amap-polygon
          :path="polygonPath"
          fillColor="#0DAAFC"
          strokeColor="#00A1E9"
          fillOpacity="0.1"
          strokeOpacity="0.3"
        ></el-amap-polygon>
        <el-amap-circle
          v-for="(circle, index) in circles"
          :center="circle.center"
          :radius="circle.radius"
          :key="index + 'c'"
          fill-opacity="0.2"
          strokeColor="#38f"
          strokeOpacity="0.8"
          strokeWeight="1"
          fillColor="#38f"
        ></el-amap-circle>
      </el-amap>
    </div>
  </div>
</template>

<script>
export default {
  name: "Map",
  props: ["width", "height", "type"],
  computed: {
    mapType() {
      return this.type;
    },
  },
  watch: {
    mapType(newV) {
      if (newV == "whole") {
        this.windows = this.markers = [];
        let markers = [];
        let windows = [];
        let that = this;
        this.$http
          .post(`${this.$baseUrl}/students/info/studentsPosition`, {
            schoolId: JSON.parse(localStorage.getItem("schoolData")).schoolId,
          })
          .then((response) => {
            for (let i = 0; i < response.data.result.length; i++) {
              markers.push({
                position: [
                  response.data.result[i].longitude,
                  response.data.result[i].latitude,
                ],
                icon: `<img src="${
                  response.data.result[i].studentPhotoUrl ==
                  "../../static/pic_head@3x.png"
                    ? require("../assets/pic_head@3x.png")
                    : response.data.result[i].studentPhotoUrl ==
                      "../../static/pic_head_girl@3x.png"
                    ? require("../assets/pic_head_girl@3x.png")
                    : response.data.result[i].studentPhotoUrl
                }" style="width:30px; height:30px; border-radius:50%" />`,
                events: {
                  click: () => {
                    alert("click marker");
                  },
                },
              });
            }
            markers.forEach((item, index) => {
              item.events = {
                click: () => {
                  that.windows.forEach((window) => {
                    window.visible = false; //关闭窗体
                  });
                  that.window = that.windows[index];
                  that.$nextTick(() => {
                    that.window.visible = true; //点击点坐标，出现信息窗体
                  });
                },
              };
              let className =
                response.data.result[index].gradeName +
                response.data.result[index].className;
              windows.push({
                position: [item.position[0], item.position[1]],
                content:
                  `<div style='width:200px;height:25px;background:#B9D5FA;font-size:14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #666666;text-align:center;display:flex;flex-direction:column;align-items:center;justify-content:center'>员工卡片</div><div style='height:80px;display:flex;flex-direction:column;justify-content:space-evenly;margin-left:5px;white-space:nowrap;'><div style='font-size:10px;max-width:190px;overflow:hidden; text-overflow:ellipsis'>姓名：${response.data.result[index].studentName}` +
                  `<span style="color:#5D9CFF;margin-left:10px">在岗</span></div><div style="font-size:10px;max-width:190px;overflow:hidden; text-overflow:ellipsis">实施组：${className}</div><div style="font-size:10px;max-width:190px;overflow:hidden; text-overflow:ellipsis">位置：${
                    response.data.result[index].location != null
                      ? response.data.result[index].location
                      : "暂无位置信息"
                  }</div></div>`, //内容
                offset: [5, -15], //窗体偏移
                visible: false, //初始是否显示
              });
            });
          });
        this.windows = windows;
        this.markers = markers;
      } else {
        this.windows = this.markers = [];
        let markers = [];
        let windows = [];
        let that = this;
        this.$http
          .post(`${this.$baseUrl}/students/info/studentsPosition`, {
            schoolId: JSON.parse(localStorage.getItem("schoolData")).schoolId,
            gradeId: newV,
          })
          .then((response) => {
            for (let i = 0; i < response.data.result.length; i++) {
              markers.push({
                position: [
                  response.data.result[i].longitude,
                  response.data.result[i].latitude,
                ],
                icon: `<img src="${
                  response.data.result[i].studentPhotoUrl ==
                  "../../static/pic_head@3x.png"
                    ? require("../assets/pic_head@3x.png")
                    : response.data.result[i].studentPhotoUrl ==
                      "../../static/pic_head_girl@3x.png"
                    ? require("../assets/pic_head_girl@3x.png")
                    : response.data.result[i].studentPhotoUrl
                }" style="width:30px; height:30px; border-radius:50%" />`,
                events: {
                  click: () => {
                    alert("click marker");
                  },
                },
              });
            }
            markers.forEach((item, index) => {
              item.events = {
                click: () => {
                  that.windows.forEach((window) => {
                    window.visible = false; //关闭窗体
                  });
                  that.window = that.windows[index];
                  that.$nextTick(() => {
                    that.window.visible = true; //点击点坐标，出现信息窗体
                  });
                },
              };
              let className =
                response.data.result[index].gradeName +
                response.data.result[index].className;
              windows.push({
                position: [item.position[0], item.position[1]],
                content:
                  `<div style='width:200px;height:25px;background:#B9D5FA;font-size:14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #666666;text-align:center;display:flex;flex-direction:column;align-items:center;justify-content:center'>员工卡片</div><div style='height:80px;display:flex;flex-direction:column;justify-content:space-evenly;margin-left:5px;white-space:nowrap;'><div style='font-size:10px;max-width:190px;overflow:hidden; text-overflow:ellipsis'>姓名：${response.data.result[index].studentName}` +
                  `<span style="color:#5D9CFF;margin-left:10px">在岗</span></div><div style="font-size:10px;max-width:190px;overflow:hidden; text-overflow:ellipsis">实施组：${className}</div><div style="font-size:10px;max-width:190px;overflow:hidden; text-overflow:ellipsis">位置：${
                    response.data.result[index].location != null
                      ? response.data.result[index].location
                      : "暂无位置信息"
                  }</div></div>`, //内容
                offset: [5, -15], //窗体偏移
                visible: false, //初始是否显示
              });
            });
          });
        this.windows = windows;
        this.markers = markers;
      }
    },
  },
  mounted() {
    this.$http
      .get(
        `${this.$baseUrl}/enclosure/findListBySchoolId?schoolId=${this.schoolData.schoolId}&enclosureType=1`
      )
      .then((response) => {
        for (let i of response.data.result) {
          console.log(i);
          this.circles.push({
            center: [i.srcLongitude, i.srcLatitude],
            radius: i.radius,
          });
        }
      });
    this.styleObject.width = this.width;
    this.styleObject.height = this.height;
    setTimeout(() => {
      this.geocoder = new window.AMap.Geocoder();
      this.geocoder.getLocation(
        JSON.parse(localStorage.getItem("schoolData")).schoolAddress,
        (status, result) => {
          this.loaded = true;
          if (status === "complete" && result.geocodes.length) {
            console.log(
              result.geocodes[0].location.lng,
              result.geocodes[0].location.lat
            );
            this.center = [
              result.geocodes[0].location.lng,
              result.geocodes[0].location.lat,
            ];
          } else {
            console.log("根据地址查询位置失败");
          }
        }
      );
    }, 1000);
  },
  methods: {
    show() {
      console.log(1);
    },
    getAddress(lnglat, i, list) {
      this.geocoder.getAddress(lnglat, (status, result) => {
        if (status === "complete" && result.info === "OK") {
          // console.log(result.regeocode.formattedAddress);
          list.push({
            pos: result.regeocode.formattedAddress,
            index: i,
          });
          return result.regeocode.formattedAddress;
        } else {
          list.push({
            pos: "获取位置失败",
            index: i,
          });
        }
      });
    },
  },
  data() {
    let markers = [];
    let windows = [];
    let that = this;
    this.$http
      .post(`${this.$baseUrl}/students/info/studentsPosition`, {
        schoolId: JSON.parse(localStorage.getItem("schoolData")).schoolId,
      })
      .then((response) => {
        for (let i = 0; i < response.data.result.length; i++) {
          // if(i==0) this.center = [
          //     response.data.result[i].longitude,
          //     response.data.result[i].latitude,
          //   ]
          markers.push({
            position: [
              response.data.result[i].longitude,
              response.data.result[i].latitude,
            ],
            icon: `<img src="${
              response.data.result[i].studentPhotoUrl ==
              "../../static/pic_head@3x.png"
                ? require("../assets/pic_head@3x.png")
                : response.data.result[i].studentPhotoUrl ==
                  "../../static/pic_head_girl@3x.png"
                ? require("../assets/pic_head_girl@3x.png")
                : response.data.result[i].studentPhotoUrl
            }" style="width:30px; height:30px; border-radius:50%" />`,
            events: {
              click: () => {
                alert("click marker");
              },
            },
          });
        }
        markers.forEach((item, index) => {
          item.events = {
            click: () => {
              that.windows.forEach((window) => {
                window.visible = false; //关闭窗体
              });
              that.window = that.windows[index];
              that.$nextTick(() => {
                that.window.visible = true; //点击点坐标，出现信息窗体
              });
            },
          };
          let className =
            response.data.result[index].gradeName +
            response.data.result[index].className;
          windows.push({
            position: [item.position[0], item.position[1]],
            content:
              `<div style='width:200px;height:25px;background:#B9D5FA;font-size:14px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #666666;text-align:center;display:flex;flex-direction:column;align-items:center;justify-content:center'>员工卡片</div><div style='height:80px;display:flex;flex-direction:column;justify-content:space-evenly;margin-left:5px;white-space:nowrap;'><div style='font-size:10px;max-width:190px;overflow:hidden; text-overflow:ellipsis'>姓名：${response.data.result[index].studentName}` +
              `<span style="color:#5D9CFF;margin-left:10px">在岗</span></div><div style="font-size:10px;max-width:190px;overflow:hidden; text-overflow:ellipsis">实施组：${className}</div><div style="font-size:10px;max-width:190px;overflow:hidden; text-overflow:ellipsis">位置：${
                response.data.result[index].location != null
                  ? response.data.result[index].location
                  : "暂无位置信息"
              }</div></div>`, //内容
            offset: [5, -15], //窗体偏移
            visible: false, //初始是否显示
          });
        });
      });
    //   for (let i = 0; i < 2500; i++) {
    //     let temp1 = Math.random() / 100 + 121.634235;
    //     let temp2 = Math.random() / 1000 + 31.199537;
    //     if (temp1 < 121.636187 && temp2 < 31.199858) {
    //       markers.push({
    //         position: [temp1, temp2],
    //         icon: require("../assets/pic_head@3x.png"),
    //         events: {
    //           click: () => {
    //             alert("click marker");
    //           },
    //         },
    //       });
    //     }
    //   }
    //   for (let i = 0; i < 2500; i++) {
    //     let temp1 = Math.random() / 100 + 121.634462;
    //     let temp2 = Math.random() / 1000 + 31.199399;
    //     if (temp1 < 121.635315 && temp2 < 31.200103) {
    //       markers.push({
    //         position: [temp1, temp2],
    //         icon: require("../assets/pic_head@3x.png"),
    //         events: {
    //           click: () => {
    //             alert("click marker");
    //           },
    //         },
    //       });
    //     }

    // }

    return {
      loaded: false,
      geocoder: undefined,
      posList: [],
      circles: [],
      plugins: [
        {
          //地图工具条
          pName: "ToolBar",
          init() {},
        },
      ],
      schoolData: JSON.parse(localStorage.getItem("schoolData")),
      styleObject: {
        width: "1160px",
        height: "840px",
      },
      polygonPath: [
        [121.634191, 31.199968],
        [121.63454, 31.199179],
        [121.636347, 31.199651],
        [121.636095, 31.200128],
        [121.635446, 31.199972],
        [121.635301, 31.20028],
      ],
      center: [116.397437, 39.909184],
      markers,
      windows,
      // markers: [
      //   {
      //     position: [121.635179, 31.199743],
      //   },
      //   {
      //     position: [121.63462, 31.19922],
      //   },
      //   {
      //     position: [121.634776, 31.199876],
      //   },
      //   {
      //     position: [121.6351375, 31.19938321],
      //   },
      //   {
      //     position: [121.6352782, 31.19972123],
      //   },
      // ],
    };
  },
};
</script>

<style scoped>
.map-container >>> .amap-info-content {
  margin: 0;
  padding: 0;
}
.map-container >>> .amap-info-close {
  right: 5px !important;
}
.map-container {
  border-radius: 16px;
  width: 1160px;
  height: 840px;
  overflow: hidden;
}

.amap-wrapper {
  border-radius: 16px;
  width: 1160px;
  height: 840px;
  overflow: hidden;
}
</style>